import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import logo from 'images/HeaderLogo.svg';
import Home from 'components/Home';
import AffiliatePopup from 'components/Home/Popup/AffiliatePopup';
import MarketingBanner from 'components/MarketingBanner';

import s from 'styles/base.module.css';
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      Popup: undefined,
    };
  }

  componentDidMount() {
    // Select popup to show
    if (this.props.location.search.includes('utm_campaign=affiliate')) {
      this.setState({
        showPopup: true,
        Popup: AffiliatePopup,
      });
    }
  }

  toggleShowPopup = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  render() {
    const { showPopup, Popup } = this.state;
    return (
      <Layout logo={logo}>
        {showPopup ? (
          <div className={s.popupWrap}>
            <div className={s.overlayBackground} />
            <Popup logo={logo} toggle={this.toggleShowPopup} />
          </div>
        ) : (
          <MarketingBanner />
        )}
        <SEO
          title="Liquid Japan - 暗号資産（仮想通貨）の購入"
          description="Liquid Japanはビットコインなどの暗号資産（仮想通貨）取引所です。無料で口座を開設でき、ビットコインの他にもリップルなどを購入できます。"
          link="/"
        />
        <Home />
      </Layout>
    );
  }
}

export default IndexPage;
