import React from 'react';

import IconBackground from './background.inline.svg';
import IconIndicator from './indicator.inline.svg';
import styles from './Loading.module.css';

const LoadingIcon = ({ width = 24, height = 24, size = 24 }) => (
  <div className={styles.main}>
    <IconBackground
      className={styles.background}
      width={size || width}
      height={size || height}
    />
    <IconIndicator
      className={styles.indicator}
      width={size || width}
      height={size || height}
    />
  </div>
);

export default LoadingIcon;
