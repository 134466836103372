import React, { useState } from 'react';

import { HIDE_CAMPAIGN_BANNER_ID } from 'constants/localStorageKey';
import { getItem, setItem } from 'utils/localStorage';

import Checkbox from './Checkbox';
import useBanner from './useBanner';
import styles from './styles.module.css';
import s from 'styles/base.module.css';

/**
 * This function is to replace the `www.liquid.com` with current origin. It won't affect the production href.
 * @param {string} href
 */
const getHref = (href) => {
  const productionOrigin = 'https://www.liquid.com';
  if (href?.includes(productionOrigin)) {
    return href?.replace(productionOrigin, window.location.origin);
  }
  return href;
};

/**
 * @param {string} url
 */
const getTarget = (url) => {
  if (url?.includes(window.location.origin)) {
    return '_self';
  }
  return '_blank';
};

const MarketingBanner = () => {
  const [closeBanner, setCloseBanner] = useState(false);
  const [closeBannerPermanently, setCloseBannerPermanently] = useState(false);
  const banner = useBanner();
  const isDisplayTimeOver = new Date(banner.until) - new Date() < 0;

  /*
   * The banner is hidden if
   * the display time is over or
   * it is closed permanently
   */
  if (
    Object.keys(banner).length === 0 ||
    banner.id === getItem(HIDE_CAMPAIGN_BANNER_ID) ||
    isDisplayTimeOver ||
    closeBanner
  ) {
    return null;
  }

  const closeBannerHandler = () => {
    if (closeBannerPermanently) {
      setItem(HIDE_CAMPAIGN_BANNER_ID, banner.id);
    }
    setCloseBanner(true);
  };

  const segmentEventHandler = () => {
    window.analytics.track(banner.id);
  };

  const href = getHref(banner.url);
  return (
    <div className={s.popupWrap}>
      <div className={s.overlayBackground} />
      <div className={styles.background}>
        <div className={styles.content}>
          <a
            onClick={segmentEventHandler}
            href={href}
            target={getTarget(href)}
            rel="noopener noreferrer"
          >
            <img src={banner.imgSrc} className={styles.img} alt={banner.id} />
          </a>
          <div className={styles.checkboxWrap}>
            <Checkbox
              checked={closeBannerPermanently}
              onChange={setCloseBannerPermanently}
            >
              次回から表示しない
            </Checkbox>
            <button onClick={closeBannerHandler} className={styles.closeButton}>
              閉じる
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingBanner;
