import { footerLinks as links } from 'constants/links';

export default [
  {
    label: '倫理コード',
    href: links().codeOfConduct,
  },
  {
    label: '反社会的勢力による被害の防止に関する基本方針',
    href: links().antisocialForces,
  },
  {
    label: '各種規約・取引ルール等',
    href: links().rulesAndEtc,
  },
  {
    label: '利益相反管理方針',
    href: links().oppositeInterestsPolicy,
  },
  {
    label: '情報セキュリティ基本ポリシー',
    href: links().informationSecurityPolicy,
  },
  {
    label: 'マネー・ローンダリングおよびテロ資金供与の防止に関するポリシー',
    href: links().terroristFinancingPolicy,
  },
  {
    label: '債務履行方針',
    href: links().debtPolicy,
  },
];
