export const defaultJpCurrencies = [
  {
    currency_type: 'fiat',
    currency: 'USD',
    symbol: '$',
    assets_precision: 2,
    quoting_precision: 5,
    minimum_withdrawal: '100.0',
    minimum_fee: null,
    minimum_order_quantity: '1.0',
    display_precision: 5,
    depositable: true,
    withdrawable: false,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '1.0',
    root_currency: 'USD',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'United States Dollar',
    is_test: false,
  },
  {
    currency_type: 'fiat',
    currency: 'EUR',
    symbol: '€',
    assets_precision: 2,
    quoting_precision: 5,
    minimum_withdrawal: '100.0',
    minimum_fee: null,
    minimum_order_quantity: '0.0',
    display_precision: 5,
    depositable: true,
    withdrawable: false,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '1.098309701',
    root_currency: 'EUR',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Euro',
    is_test: false,
  },
  {
    currency_type: 'fiat',
    currency: 'SGD',
    symbol: 'S$',
    assets_precision: 2,
    quoting_precision: 5,
    minimum_withdrawal: '10.0',
    minimum_fee: null,
    minimum_order_quantity: '0.0',
    display_precision: 5,
    depositable: true,
    withdrawable: false,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.752671985548697877465000753',
    root_currency: 'SGD',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Singaporean Dollar',
    is_test: false,
  },
  {
    currency_type: 'fiat',
    currency: 'HKD',
    symbol: 'HK$',
    assets_precision: 2,
    quoting_precision: 5,
    minimum_withdrawal: '800.0',
    minimum_fee: null,
    minimum_order_quantity: '0.0',
    display_precision: 5,
    depositable: true,
    withdrawable: false,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.128100430737698355510720405',
    root_currency: 'HKD',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Hong Kong Dollar',
    is_test: false,
  },
  {
    currency_type: 'fiat',
    currency: 'AUD',
    symbol: 'AU$',
    assets_precision: 2,
    quoting_precision: 5,
    minimum_withdrawal: '140.0',
    minimum_fee: null,
    minimum_order_quantity: '0.0',
    display_precision: 5,
    depositable: true,
    withdrawable: false,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.669936851752353823128631895',
    root_currency: 'AUD',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Australian Dollar',
    is_test: false,
  },
  {
    currency_type: 'fiat',
    currency: 'CNY',
    symbol: '¥',
    assets_precision: 2,
    quoting_precision: 5,
    minimum_withdrawal: '150.0',
    minimum_fee: null,
    minimum_order_quantity: null,
    display_precision: 5,
    depositable: false,
    withdrawable: false,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.141258899310656571363995932',
    root_currency: 'CNY',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Chinese Yuan',
    is_test: false,
  },
  {
    currency_type: 'fiat',
    currency: 'INR',
    symbol: '₹',
    assets_precision: 2,
    quoting_precision: 5,
    minimum_withdrawal: '2000.0',
    minimum_fee: null,
    minimum_order_quantity: null,
    display_precision: 5,
    depositable: false,
    withdrawable: false,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.012003124077109797521220443',
    root_currency: 'INR',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Indian Rupee',
    is_test: false,
  },
  {
    currency_type: 'fiat',
    currency: 'JPY',
    symbol: '¥',
    assets_precision: 2,
    quoting_precision: 5,
    minimum_withdrawal: '100.0',
    minimum_fee: null,
    minimum_order_quantity: '0.0',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: true,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.007030074659392882752414831',
    root_currency: 'JPY',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Japanese Yen',
    is_test: false,
  },
  {
    currency_type: 'fiat',
    currency: 'PHP',
    symbol: '₱',
    assets_precision: 2,
    quoting_precision: 5,
    minimum_withdrawal: '675.0',
    minimum_fee: null,
    minimum_order_quantity: null,
    display_precision: 5,
    depositable: false,
    withdrawable: false,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.01795428902649295389313442',
    root_currency: 'PHP',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Philippine peso',
    is_test: false,
  },
  {
    currency_type: 'fiat',
    currency: 'IDR',
    symbol: 'Rp',
    assets_precision: 2,
    quoting_precision: 5,
    minimum_withdrawal: '200000.0',
    minimum_fee: '25000.0',
    minimum_order_quantity: null,
    display_precision: 5,
    depositable: false,
    withdrawable: false,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.000064621190580815260940368',
    root_currency: 'IDR',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Indonesian Rupiah',
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'BTC',
    symbol: '₿',
    assets_precision: 8,
    quoting_precision: 8,
    minimum_withdrawal: '0.001',
    minimum_fee: null,
    minimum_order_quantity: '0.0001',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '42920.89366144776',
    root_currency: 'BTC',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: '25.0',
    name: 'Bitcoin',
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'ETH',
    symbol: 'Ξ',
    assets_precision: 18,
    quoting_precision: 8,
    minimum_withdrawal: '0.02',
    minimum_fee: null,
    minimum_order_quantity: '0.002',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '2302.4757417509213',
    root_currency: 'ETH',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: '100.0',
    name: 'Ethereum',
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'LTC',
    symbol: 'Ł',
    assets_precision: 8,
    quoting_precision: 8,
    minimum_withdrawal: '0.0025',
    minimum_fee: null,
    minimum_order_quantity: '0.001',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '72.46444293917',
    root_currency: 'LTC',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Litecoin',
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'XRP',
    symbol: 'ρ',
    assets_precision: 6,
    quoting_precision: 6,
    minimum_withdrawal: '21.0',
    minimum_fee: null,
    minimum_order_quantity: '1.0',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: true,
    stable_currency: null,
    exchange_rate: '0.6314264894433957',
    root_currency: 'XRP',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: '400000.0',
    name: 'Ripple',
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'BCH',
    symbol: '฿',
    assets_precision: 8,
    quoting_precision: 8,
    minimum_withdrawal: '0.002',
    minimum_fee: null,
    minimum_order_quantity: '0.01',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '236.34659853070548',
    root_currency: 'BCH',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: '150.0',
    name: 'Bitcoin Cash',
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'DOT',
    symbol: 'DOT',
    assets_precision: 10,
    quoting_precision: 8,
    minimum_withdrawal: '4.65',
    minimum_fee: null,
    minimum_order_quantity: '1.0',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '7.493455893991616',
    root_currency: 'DOT',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Polkadot',
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'DOGE',
    symbol: 'DOGE',
    assets_precision: 8,
    quoting_precision: 8,
    minimum_withdrawal: '50.0',
    minimum_fee: null,
    minimum_order_quantity: '13.0',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.09827525664742837',
    root_currency: 'DOGE',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: null,
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'BAT',
    symbol: 'BAT',
    assets_precision: 18,
    quoting_precision: 8,
    minimum_withdrawal: '10.0',
    minimum_fee: null,
    minimum_order_quantity: '1.0',
    display_precision: 6,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.24679075898843536',
    root_currency: 'ETH',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: null,
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'MKR',
    symbol: 'MKR',
    assets_precision: 18,
    quoting_precision: 8,
    minimum_withdrawal: '0.028',
    minimum_fee: null,
    minimum_order_quantity: '0.007',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '1356.0137842482468',
    root_currency: 'ETH',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: null,
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'ENJ',
    symbol: 'ENJ',
    assets_precision: 18,
    quoting_precision: 8,
    minimum_withdrawal: '50.0',
    minimum_fee: null,
    minimum_order_quantity: '100.0',
    display_precision: 8,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.3559591791108439',
    root_currency: 'ETH',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: null,
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'OMG',
    symbol: 'OMG',
    assets_precision: 18,
    quoting_precision: 8,
    minimum_withdrawal: '3.0',
    minimum_fee: null,
    minimum_order_quantity: '2.0',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.9193255187876429',
    root_currency: 'ETH',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: null,
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'FTT',
    symbol: 'FTT',
    assets_precision: 18,
    quoting_precision: 8,
    minimum_withdrawal: '0.6',
    minimum_fee: null,
    minimum_order_quantity: '0.1',
    display_precision: 5,
    depositable: false,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '4.919121024729374',
    root_currency: 'ETH',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'FTX Token',
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'SOL',
    symbol: 'SOL',
    assets_precision: 9,
    quoting_precision: 8,
    minimum_withdrawal: '0.05',
    minimum_fee: null,
    minimum_order_quantity: '0.1',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '74.67730096538965',
    root_currency: 'SOL',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Solana',
    is_test: false,
  },
  {
    currency_type: 'crypto',
    currency: 'AVAX',
    symbol: 'AVAX',
    assets_precision: 18,
    quoting_precision: 8,
    minimum_withdrawal: '0.14',
    minimum_fee: null,
    minimum_order_quantity: '0.07',
    display_precision: 5,
    depositable: true,
    withdrawable: true,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '38.722327804455',
    root_currency: 'AVAX',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Avalanche',
    is_test: false,
  },
  {
    currency_type: 'fiat',
    currency: 'TJPY',
    symbol: 'TJPY',
    assets_precision: 5,
    quoting_precision: 5,
    minimum_withdrawal: '0.0',
    minimum_fee: null,
    minimum_order_quantity: '0.01',
    display_precision: 5,
    depositable: false,
    withdrawable: false,
    discount_fee: 0,
    credit_card_fundable: false,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.0',
    root_currency: 'TJPY',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Test JPY',
    is_test: true,
  },
  {
    currency_type: 'crypto',
    currency: 'TBTC',
    symbol: 'TBTC',
    assets_precision: 8,
    quoting_precision: 8,
    minimum_withdrawal: '0.0',
    minimum_fee: null,
    minimum_order_quantity: '0.01',
    display_precision: 5,
    depositable: false,
    withdrawable: false,
    discount_fee: 0,
    lendable: false,
    position_fundable: false,
    has_memo: false,
    stable_currency: null,
    exchange_rate: '0.0',
    root_currency: 'TBTC',
    minimum_loan_bid_quantity: '0.0',
    maximum_order_taker_quantity: null,
    name: 'Test Bitcoin',
    is_test: true,
  },
];
