import React from "react"
import { navigate } from "gatsby"
import { liquidSignUp } from "constants/links"
import Radios from "components/Button/RadioButton"
import { registerViaEmails } from "resources/signUp"
import { loadJp as loadTerms } from "resources/terms"

import styles from "./SignUpPopup.module.css"

const ACCOUNT_TYPES = [
  {
    value: "individual",
    label: "個人アカウント",
  },
  {
    value: "corporate",
    label: "法人アカウント",
  },
]
const LOCATIONS = [
  {
    value: "jp",
    label: "日本",
  },
  {
    value: "others",
    label: "日本以外",
  },
]

class SignUpPopup extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      userType: "individual",
      country: "jp",
    }
  }

  componentDidMount() {
    loadTerms().then(({ version }) => {
      this.setState({ termVersion: version })
    })
  }

  onChangeAccount = (userType) => {
    this.setState({ userType, error: "" })
  }

  onChangeCountry = (country) => {
    this.setState({ country, error: "" })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const email = e.target.email.value
    const { ftxSig } = this.props;
    const { userType, country, termVersion } = this.state
    const body = {
      user_type: userType,
      country,
      email,
      term_version: termVersion,
      ftx_sig: ftxSig,
    }

    return registerViaEmails(body)
      .then(({ id }) => {
        navigate(`/email-sent?user=${id}&email=${email}`, {
          state: { email, id },
        })
      })
      .catch((error) => {
        // BE would send a new confirmation email to user and expire the former one
        if (error.message === 'sent-confirmation-instructions') {
          navigate(`/email-sent?email=${email}`, {
            state: { email },
          })
        } else {
          console.log("pop", error, error.message)
          this.setState({ error })
        }
      })
  }

  render() {
    const { isMobile } = this.props
    const { userType, country, error } = this.state
    const errorMessage = () => error.message === 'email-has-already-been-taken'
      ? 'このメールアドレスは既に登録されています。'
      : error.message === 'invalid-email-domain'
        ? '無効なメールアドレスです。'
        : '処理に失敗しました。'

    return (
      <>
        <form method="post" onSubmit={this.onSubmit} className={styles.form}>
          <div className={styles.buttons}>
            {LOCATIONS.map((location) => (
              <button
                id={`country-${location.value}`}
                type="button"
                onClick={() => this.onChangeCountry(location.value)}
                className={`${styles.countryButton} ${
                  location.value === country ? styles.countryButton_active : ""
                }`}
              >
                {location.label}
              </button>
            ))}
          </div>
          {country === "jp" ? (
            <div className={styles.content}>
              <div className={styles.radios}>
                {ACCOUNT_TYPES.map((type) => (
                  <Radios
                    id={`account-${type.value}${isMobile ? "-mobile" : ""}`}
                    onChange={this.onChangeAccount}
                    value={type.value}
                    label={type.label}
                    checked={type.value === userType}
                    className={styles.radio}
                  />
                ))}
              </div>
              <div className={styles.bottom}>
                <input
                  id="email"
                  autoComplete="off"
                  className={styles.email}
                  name="email"
                  type="email"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  placeholder="メールアドレスを入力"
                  required
                />
                <button
                  type="submit"
                  className={styles.button}
                  disabled={country === "others"}
                >
                  無料で口座開設
                </button>
              </div>
              {error && (
                <div className={styles.error}>
                  {errorMessage()}
                </div>
              )}
            </div>
          ) : (
            <div className={styles.note}>
              <div>居住地国が日本以外のお客様は</div>
              <div>
                弊社
                <a href={liquidSignUp}>グローバルウェブサイト</a>
                からご登録ください。
              </div>
            </div>
          )}
        </form>
      </>
    )
  }
}

export default SignUpPopup
