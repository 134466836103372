import TelegramIcon from './TelegramIcon.png';
import FacebookIcon from './FacebookIcon.png';
import TwitterIcon from './TwitterIcon.png';
import YoutubeIcon from './YoutubeIcon.png';
import LineIcon from './LineIcon.png';

const navData = () => ([
  // {
  //   label: 'Telegram',
  //   icon: TelegramIcon,
  //   size: { width: 37, height: 37 },
  //   link: 'telegram',
  // },
  // {
  //   label: 'Facebook',
  //   icon: FacebookIcon,
  //   size: { width: 14, height: 26 },
  //   link: 'facebook',
  // },
  {
    label: 'Twitter',
    icon: TwitterIcon,
    size: { width: 30, height: 24 },
    link: 'twitter',
  },
  // {
  //   label: 'Youtube',
  //   icon: YoutubeIcon,
  //   size: { width: 33, height: 23 },
  //   link: 'youtube',
  // },
  // {
  //   label: 'Line',
  //   icon: LineIcon,
  //   size: { width: 37, height: 37 },
  //   link: 'line',
  // },
]);

export default navData;
