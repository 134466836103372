const removeElement = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.parentNode.removeChild(element);
  }
};

export const appendImgs = (imgSrcs) => {
  imgSrcs.forEach((src, index) => {
    // generate id
    const id = `affliate-tag-img--${index}`;

    // remove if exist
    removeElement(id);

    // create affilate tag
    const tag = new Image(1, 1);
    tag.id = id;
    tag.src = src;
    tag.style.display = 'none';

    // append the tag to body
    document.head.appendChild(tag);
  });
};

export const appendScripts = (scripts) => {
  scripts.forEach((srp, index) => {
    // generate id
    const id = `marketing-tag-script--${srp.id || index}`;

    // remove if exist
    removeElement(id);

    // create marketing tag
    if (!srp.noscript) {
      const script = document.createElement('script');
      script.id = id;
      script.type = 'text/javascript';
      if (srp.src) {
        if (srp.async) {
          script.async = srp.async;
        }
        script.src = srp.src;
      }
      if (srp.innerHTML) {
        script.innerHTML = srp.innerHTML;
      }

      // append the tag to body
      document.head.appendChild(script);
    } else {
      const noscript = document.createElement('noscript');
      noscript.id = id;
      noscript.innerHTML = srp.noscript;

      // append the tag to body
      document.head.appendChild(noscript);
    }
  });
};

// scripts to append
export const scripts = [
  // Yahoo Code for your Target list
  {
    innerHTML: `
        /* <![CDATA[ */
          var yahoo_ss_retargeting_id = 1001043643;
          var yahoo_sstag_custom_params = window.yahoo_sstag_params;
          var yahoo_ss_retargeting = true;
          /* ]]> */
      `,
  },
  {
    src: 'https://s.yimg.jp/images/listing/tool/cv/conversion.js',
  },
  {
    noscript: `
        <div style="display:inline;">
          <img height="1" width="1" style="border-style:none;" alt="" src="https://b97.yahoo.co.jp/pagead/conversion/1001043643/?guid=ON&script=0&disvt=false"/>
        </div>
      `,
  },
  {
    innerHTML: `
        /* <![CDATA[ */
          var yahoo_retargeting_id = 'HLYHAR7A8P';
          var yahoo_retargeting_label = '';
          var yahoo_retargeting_page_type = '';
          var yahoo_retargeting_items = [{item_id: '', category_id: '', price: '', quantity: ''}];
          /* ]]> */
      `,
  },
  {
    src: 'https://b92.yahoo.co.jp/js/s_retargeting.js',
  },
  {
    async: true,
    src: 'https://s.yimg.jp/images/listing/tool/cv/ytag.js',
  },
  {
    innerHTML: `
      window.yjDataLayer = window.yjDataLayer || [];
      function ytag() { yjDataLayer.push(arguments); }
      ytag({"type":"ycl_cookie", "config":{"ycl_use_non_cookie_storage":true}});
    `,
  },

  //Facebook Pixel
  {
    innerHTML: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '194279987933827');
        fbq('track', 'PageView');
      `,
  },
  {
    noscript: `
        <img height="1" width="1" style="display:none" 
        src="https://www.facebook.com/tr?id=194279987933827&ev=PageView&noscript=1"/>
      `,
  },
  //Google
  {
    async: true,
    src: 'https://www.googletagmanager.com/gtag/js?id=AW-797420929',
  },
  {
    innerHTML: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', 'AW-797420929');
      `,
  },
  // Add Google optimize snippet
  {
    src: 'https://www.googleoptimize.com/optimize.js?id=OPT-KGGL2QC',
  },
  //Heatmap
  {
    id: 'mierucajs',
    innerHTML: `
        window.__fid = window.__fid || [];__fid.push([468372007]);
        (function() {
        function mieruca(){if(typeof window.__fjsld != "undefined") return; window.__fjsld = 1; var fjs = document.createElement('script'); fjs.type = 'text/javascript'; fjs.async = true; fjs.id = "fjssync"; var timestamp = new Date;fjs.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://hm.mieru-ca.com/service/js/mieruca-hm.js?v='+ timestamp.getTime(); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(fjs, x); };
        setTimeout(mieruca, 500); document.readyState != "complete" ? (window.attachEvent ? window.attachEvent("onload", mieruca) : window.addEventListener("load", mieruca, false)) : mieruca();
        })();
      `,
  },
  // affiliate site tracking
  {
    innerHTML: `
      (function(){
        var uqid = "5dae53Dceda2XD48";
        var cid  = "158";
        
        var a=document.createElement("script");
        a.dataset.uqid=uqid;a.dataset.cid=cid;a.id="afadfpc-"+Date.now();
        a.src="//ad.mtrf.net/fpc/cookie_js.php?scriptId="+encodeURIComponent(a.id);
        document.body.appendChild(a);
        })();
      `,
  },
];

const addJpMarketingTags = () => {
  // add scripts
  appendScripts(scripts);
};

export default addJpMarketingTags;
