const defaultPrcn = 4;

export const fundingCurrencies = [
  'USD', 'IDR', 'AUD', 'PHP', 'CNY',
  'INR', 'EUR', 'JPY', 'SGD', 'HKD',
  'BTC', 'QASH',
];

/* eslint-disable object-property-newline */
const fallbackPrcn = {
  AUD: 2, CNY: 2, EUR: 2, HKD: 2, SGD: 2, USD: 3,
  IDR: 0, INR: 0, JPY: 2, PHP: 0,
  // ===
  BTC: 8, ETH: 8, BCH: 8,
  // ===
  ETC: 4, LTC: 4, XRP: 4, XMR: 4, ZEC: 4, XEM: 4, XLM: 4, DASH: 4, NEO: 4, FCT: 4,
  STORJ: 4, QTUM: 4, OAX: 4, STX: 4, VET: 4, QASH: 4, TRX: 4, REP: 4,
};
/* eslint-enable object-property-newline */

const getPrecision = (raw) =>
  [fallbackPrcn[raw.currency], defaultPrcn]
    // note 1: 0 is a valid value
    // note 2: NaN is not a valid value
    .find(candidate => !isNaN(candidate));

// ===

export default (raw) => ({
  funding: fundingCurrencies.indexOf(raw.currency) >= 0,
  type: raw.currency_type,
  currency: raw.currency,
  symbol: raw.symbol,
  precision: getPrecision(raw),
  withdrawal: {
    min: Number(raw.minimum_withdrawal),
    fee: Number(raw.withdrawal_fee),
    feeMin: Number(raw.minimum_fee),
  },
  minOrderQty: Number(raw.minimum_order_quantity),
});
