// allow store a javascript object
const setItem = (key, payload) => {
  window.localStorage.setItem(key, JSON.stringify(payload));
};

const getItem = (key) => {
  const str = window.localStorage.getItem(key);
  if (str) {
    try {
      return JSON.parse(str);
    } catch {
      return null;
    }
  }
  return null;
};

export { setItem, getItem };
