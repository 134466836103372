const tempLink = '/';
const APP_URL = 'https://app.liquid.com';
export const liquid = 'https://www.liquid.com';
export const liquidSignUp = 'https://www.liquid.com/sign-up';
export const ZENDESK_JP = 'https://quoinexjp.zendesk.com/hc/ja';
const BLOG = 'https://news.liquid.com/ja/';
const APP_STORE = 'https://go.onelink.me/app/aa7b3e09';
const GOOGLE_PLAY = 'https://go.onelink.me/app/c7b803e5';

export const ftxJpSignUpUrl = 'https://ftx.com/jp/onboarding/signup?utm_campaign=ftxjp_launch&utm_medium=banner&utm_source=liquid';
export const ftxJpHomeUrl = 'https://ftx.com/jp?utm_campaign=ftxjp_launch&utm_medium=banner&utm_source=liquid';

export const links = () => {
  return {
    liquidLPMain: tempLink,
    support: 'https://support-jp.liquid.com/hc/ja',
    contactSupport: 'https://support-jp.liquid.com/hc/ja/requests/new',
    signUpHelp: 'https://support-jp.liquid.com/hc/ja/articles/360016317891',
    qexHelp: 'https://support-jp.liquid.com/hc/ja/articles/4417484055181',

    signUp: '/',
    btcLeverage: `${APP_URL}/ja-jp/margin/BTCJPY`,

    appStore: APP_STORE,
    googlePlay: GOOGLE_PLAY,

    leverage: '/leverage/',
    roadmap: '/roadmap/',
    company: '/company/',
    fee: '/fee/',
    news: '/news/',
    security: '/security/',
    whatIsBitcoin: '/what-is-bitcoin/',
    backgroundBitcoin: '/background-bitcoin/',
    mechanismOfBitcoin: '/mechanism-of-bitcoin/',
    meritBitcoin: '/merit-bitcoin/',
    issueBitcoin: '/issue-bitcoin/',
    otherInfoBitcoin: '/other-info-bitcoin/',
    chart: '/check-our-chart/',
    chartBTC: '/check-our-chart-btc/',
    chartETH: '/check-our-chart-eth/',
    chartXRP: '/check-our-chart-xrp/',
    chartDOT: '/check-our-chart-dot/',
    chartBCH: '/check-our-chart-bch/',
    chartQASH: '/check-our-chart-qash/',
    chartLTC: '/check-our-chart-ltc/',
    chartXLM: '/check-our-chart-xlm/',
    chartBAT: '/check-our-chart-bat/',
    chartSOL: '/check-our-chart-sol/',
    chartFTT: '/check-our-chart-ftt/',
    tradeIntro: '/how-to-start-trade/',
    chartIntro: '/how-to-see-chart/',
    tradingCurrency: '/trading-currency/',
    tradingCurrencyBitcoin: '/trading-currency/bitcoin',
    tradingCurrencyEthereum: '/trading-currency/ethereum',
    tradingCurrencyBitcoinCash: '/trading-currency/bitcoincash',
    tradingCurrencyRipple: '/trading-currency/ripple',
    tradingCurrencyQash: '/trading-currency/qash',
    token: '/token/',
    quickExchange: '/quick-exchange/',
    recaptchaHelp: `${ZENDESK_JP}/articles/360046502511`,
    tfaHelp: `${ZENDESK_JP}/articles/360032764511`,
    openAccountHelp: `${ZENDESK_JP}/articles/360018266351`,
    registerBankHelp: `${ZENDESK_JP}/articles/360015834491`,
    bankDepositHelp: `${ZENDESK_JP}/articles/360004869232`,
    quickDepositHelp: `${ZENDESK_JP}/articles/360003553231`,
    quickDepositLiteHelp: `${ZENDESK_JP}/articles/360006992851`,
    bankDepositLiteHelp: `${ZENDESK_JP}/articles/360003924071`,
    depositCryptoHelp: `${ZENDESK_JP}/articles/360015689371`,
    depositCryptoLiteHelp: `${ZENDESK_JP}/articles/360006696472`,
    depositFiatHelp: `${ZENDESK_JP}/articles/360045333971`,
    depositFiatLiteHelp: `${ZENDESK_JP}/articles/360028768191`,
    marketOrderHelp: `${ZENDESK_JP}/articles/115009265187`,
    marketOrderLiteHelp: `${ZENDESK_JP}/articles/360007141651`,
    limitOrderHelp: `${ZENDESK_JP}/articles/360017774171`,
    limitOrderLiteHelp: `${ZENDESK_JP}/articles/360003893872`,
    withdrawalFiatHelp: `${ZENDESK_JP}/articles/360015685692`,
    withdrawalFiatLiteHelp: `${ZENDESK_JP}/articles/360003925071`,
    withdrawalCryptoHelp: `${ZENDESK_JP}/articles/360015637092`,
    regulations: `${ZENDESK_JP}/articles/115009415988`,
    blog: BLOG,
    leverageBlog: `${BLOG}/knowledge/bitcoin-levarage`,
    losscutBlog: `${BLOG}/glossary/failure/`,
    technicalBlog: `${BLOG}/author/ひろぴー/`,
    mikeTwitter: 'https://twitter.com/MikeKayamoriJP',
    banner: 'https://be.liquid.com/ja/first-anniversary-campaign-2019',
    pos: 'https://support-jp.liquid.com/hc/ja/categories/19622210985485',

    newsZendesk: 'https://support-jp.liquid.com/hc/ja/categories/360002224831',
  };
};

export const headerLinks = (affiliate = '') => {
  return {
    liquidLPMain: tempLink,

    support: ZENDESK_JP,
    blog: BLOG,
    global: liquid,

    login: `${APP_URL}/ja-jp/sign-in`,
    signup: `${APP_URL}/ja-jp/sign-up${affiliate ? `?${affiliate}` : ''}`,

    markets: `${APP_URL}/ja-jp/markets`,
    trade: `${APP_URL}/ja-jp/exchange/BTCJPY`,
    leverage: `${APP_URL}/ja-jp/margin/BTCJPY`,

    tradingCurrency: '/trading-currency/',
    fee: '/fee/',
    chart: '/check-our-chart/',
    chartBTC: '/check-our-chart-btc',
    liteApp: `${liquid}/lite-app`,
    security: '/security/',

    company: '/company/',
    roadmap: '/roadmap/',

    bitcoinIntro: '/what-is-bitcoin/',
    chartIntro: '/how-to-see-chart/',
    tradeIntro: '/how-to-start-trade/',
  };
};

export const chartLink = (symbol = '') => {
  return `${APP_URL}/ja-jp/exchange/${symbol}`;
};

export const qexLink = (from, to, from_qty) => {
  if (from && to && from_qty) {
    return `${APP_URL}/ja-jp/quick-exchange/?to=${to}&from=${from}&from_qty=${from_qty}&mode=swap`;
  }
  if (from && to) {
    return `${APP_URL}/ja-jp/quick-exchange/?to=${to}&from=${from}&mode=swap`;
  }
  return `${APP_URL}/ja-jp/quick-exchange/`;
};

export const footerLinks = () => {
  return {
    market: `${APP_URL}/ja-jp/markets`,
    trade: `${APP_URL}/ja-jp/exchange/BTCJPY`,
    btcLeverage: `${APP_URL}/ja-jp/margin/BTCJPY`,

    tradingCurrency: '/trading-currency/',
    fee: '/fee/',
    leverage: '/leverage/',
    chart: '/check-our-chart/',
    liteApp: `${liquid}/lite-app`,
    security: '/security/',
    apiDocsJP: 'https://document.liquid.com/',
    apiDocsEN: 'https://developers.liquid.com/',

    aboutUs: '/company/',
    roadmap: '/roadmap/',
    careers: 'https://careers.quoine.com/',

    bitcoinIntro: '/what-is-bitcoin/',
    chartIntro: '/how-to-see-chart/',
    tradeIntro: '/how-to-start-trade/',
    blog: BLOG,

    support: 'https://support-jp.liquid.com/hc/ja',
    contactSupport: 'https://support-jp.liquid.com/hc/ja/requests/new',
    contactSupportRequest: 'https://support-jp.liquid.com/hc/ja/requests/new?_ga=2.28628858.753787661.1640087515-493505797.1640145651&_gl=1*2fszlo*_ga*NDkzNTA1Nzk3LjE2NDAxNDU2NTE.*_ga_KM66WLZ8WM*MTY0MDE0NTY4NS41Ni4wLjE2NDAxNDU2OTEuNTQ.',
    complaints: `${ZENDESK_JP}/articles/26895805025549`,

    codeOfConduct: 'https://support-jp.liquid.com/hc/ja/articles/115009415988',
    antisocialForces: 'https://support-jp.liquid.com/hc/ja/articles/115009415988',
    rulesAndEtc: `${ZENDESK_JP}/articles/115009415988`,
    oppositeInterestsPolicy: 'https://support-jp.liquid.com/hc/ja/articles/115009415988',
    businessReport: 'https://quoine-docs.s3-ap-northeast-1.amazonaws.com/%E7%AC%AC4%E6%9C%9F%E4%BA%8B%E6%A5%AD%E5%A0%B1%E5%91%8A.pdf',
    businessReport2: 'https://quoine-docs.s3-ap-northeast-1.amazonaws.com/%E7%AC%AC5%E6%9C%9F%E4%BA%8B%E6%A5%AD%E5%A0%B1%E5%91%8A.pdf',
    businessReport3: 'https://quoine-docs.s3.ap-northeast-1.amazonaws.com/%E7%AC%AC6%E6%9C%9F%E4%BA%8B%E6%A5%AD%E5%A0%B1%E5%91%8A.pdf',
    financialInfo: 'https://quoine-docs.s3-ap-northeast-1.amazonaws.com/%E7%AC%AC4%E6%9C%9F%E8%A8%88%E7%AE%97%E6%9B%B8%E9%A1%9E.pdf',
    financialInfo2: 'https://quoine-docs.s3-ap-northeast-1.amazonaws.com/%E7%AC%AC5%E6%9C%9F%E8%A8%88%E7%AE%97%E6%9B%B8%E9%A1%9E.pdf',
    financialInfo3: 'https://quoine-docs.s3.ap-northeast-1.amazonaws.com/%E7%AC%AC6%E6%9C%9F%E8%A8%88%E7%AE%97%E6%9B%B8%E9%A1%9E.pdf',
    capitalStatus2021: 'https://quoine-docs.s3.ap-northeast-1.amazonaws.com/2021%E5%B9%B412%E6%9C%8831%E6%97%A5+%E8%87%AA%E5%B7%B1%E8%B3%87%E6%9C%AC%E8%A6%8F%E5%88%B6%E6%AF%94%E7%8E%87.pdf',
    capitalStatus2022: 'https://quoine-docs.s3.ap-northeast-1.amazonaws.com/2022%E5%B9%B43%E6%9C%8831%E6%97%A5%E8%87%AA%E5%B7%B1%E8%B3%87%E6%9C%AC%E8%A6%8F%E5%88%B6%E6%AF%94%E7%8E%87%E3%81%AE%E7%8A%B6%E6%B3%81.pdf',
    capitalStatus2022SecondHalf: 'https://quoine-docs.s3.ap-northeast-1.amazonaws.com/2022%E5%B9%B46%E6%9C%8830%E6%97%A5%E8%87%AA%E5%B7%B1%E8%B3%87%E6%9C%AC%E8%A6%8F%E5%88%B6%E6%AF%94%E7%8E%87%E3%81%AE%E7%8A%B6%E6%B3%81.pdf',
    informationSecurityPolicy: 'https://quoine-docs.s3.ap-northeast-1.amazonaws.com/%E6%83%85%E5%A0%B1%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E5%9F%BA%E6%9C%AC%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC.pdf',
    terroristFinancingPolicy: 'https://quoine-docs.s3.ap-northeast-1.amazonaws.com/%E3%83%9E%E3%83%8D%E3%83%BC%E3%83%BB%E3%83%AD%E3%83%BC%E3%83%B3%E3%83%80%E3%83%AA%E3%83%B3%E3%82%B0%E3%81%8A%E3%82%88%E3%81%B3%E3%83%86%E3%83%AD%E8%B3%87%E9%87%91%E4%BE%9B%E4%B8%8E%E3%81%AE%E9%98%B2%E6%AD%A2%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC.pdf',
    debtPolicy: 'https://quoine-docs.s3.ap-northeast-1.amazonaws.com/%E5%82%B5%E5%8B%99%E5%B1%A5%E8%A1%8C%E6%96%B9%E9%87%9D.pdf',
    propertyStatus: 'https://quoine-docs.s3.ap-northeast-1.amazonaws.com/%E7%AC%AC6%E6%9C%9F%E6%A5%AD%E5%8B%99%E5%8F%88%E3%81%AF%E8%B2%A1%E7%94%A3%E3%81%AE%E7%8A%B6%E6%B3%81%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E8%AA%AC%E6%98%8E%E6%9B%B8.pdf',
    feeHelp: 'https://quoinexjp.zendesk.com/hc/ja/articles/360031264131',
    status: 'https://status.liquid.com/',

    news: '/news/',
    newsZendesk: 'https://support-jp.liquid.com/hc/ja/categories/360002224831',
  };
};

export const socialLinks = () => ({
  telegram: 'https://t.me/joinchat/GuaLbkJUWB5nVgzLYlV-YA',
  twitter: 'https://twitter.com/liquidbyftx',
  facebook: 'https://www.facebook.com/liquidbyftx',
  youtube: 'https://www.youtube.com/channel/UCB1-ZWiCtWB9VlcsNYNVQHQ',
  instagram: 'https://www.instagram.com/liquid_global',
  linkedin: 'https://www.linkedin.com/company/6636706',
  reddit: 'https://reddit.com/r/Liquid',
  line: 'https://lin.ee/wozF7Nu',
});
