import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import SectionWrapper from 'components/SectionWrapper';
import Button from 'components/Button';

import HeaderLogo from './HeaderLogo.inline.svg';
import FTXJP_LOGO_BLACK from './FTXJP_LOGO_BLACK.inline.svg';
import ArrowRight from './Arrow-Right.inline.svg';
import AlertChecked from './AlertChecked.inline.svg';

import styles from './Banner.module.css';

const EmailNonConflictCase = ({ ftxUserEmail, liquidUserEmail }) => {
  return (
    <section className={styles.main}>
      <SectionWrapper>
        <div className={styles.pcKeyVisual}>
          <div className={styles.pcKeyVisual_left}>
            <div className={classnames(styles.image, styles.imageFtxToLiquid)}>
              <FTXJP_LOGO_BLACK width={115} height={42} />
              <ArrowRight height={42} style={{ margin: '0 16px' }} />
              <HeaderLogo width={115} height={42} />
            </div>
            <div className={styles.afterMigrationText}>
              <div>FTX Japanのお客様へ、</div>
              <div>お客様のFTX Japan口座は、Liquid Japanへ移管されました。</div>
              <div>
                これはFTX
                Japanプラットフォームを閉鎖するための最終ステップとして実行されました。
              </div>
              <div>
                詳細については
                <a
                  href="https://support-jp.liquid.com/hc/ja/articles/27696005108109"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#00a3e0', textDecoration: 'underline' }}
                >
                  こちら
                </a>
                をご確認ください。
              </div>
              <hr
                style={{
                  marginTop: '12px',
                  marginBottom: '12px',
                  borderTop: '1px solid var(--t-4-a30)',
                }}
              />
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    content: '',
                    left: '7.5px',
                    top: '18px',
                    bottom: '-24px',
                    width: '1px',
                    background: 'var(--success)',
                  }}
                />
                <AlertChecked
                  style={{
                    position: 'absolute',
                    content: '',
                    top: '4px',
                    left: 0,
                    height: '16px',
                    width: '16px',
                    color: 'var(--success)',
                  }}
                />
                <div style={{ marginLeft: '24px', marginBottom: '16px' }}>
                  <div style={{ fontWeight: '600' }}>
                    お客様のFTX Japan口座のメールアドレスは以下です。
                  </div>
                  <div>{ftxUserEmail}</div>
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    content: '',
                    left: '7.5px',
                    top: '18px',
                    bottom: '-24px',
                    width: '1px',
                    background: 'var(--success)',
                  }}
                />
                <AlertChecked
                  style={{
                    position: 'absolute',
                    content: '',
                    top: '4px',
                    left: 0,
                    height: '16px',
                    width: '16px',
                    color: 'var(--success)',
                  }}
                />
                <div style={{ marginLeft: '24px', marginBottom: '16px' }}>
                  <div style={{ fontWeight: '600' }}>
                    お客様の新しいLiquid Japan口座のメールアドレスは以下です。
                  </div>
                  <div>{liquidUserEmail}</div>
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <AlertChecked
                  style={{
                    position: 'absolute',
                    content: '',
                    top: '4px',
                    left: 0,
                    height: '16px',
                    width: '16px',
                    color: 'var(--t-4)',
                  }}
                />
                <div style={{ marginLeft: '24px', marginBottom: '16px' }}>
                  <div style={{ fontWeight: '600', marginBottom: '8px' }}>
                    お客様の新しいLiquid
                    Japan口座は本人確認の準備ができています。
                  </div>
                  <div>
                    まずはパスワードリセットを完了させてください。セキュリティ上の理由により、FTX
                    Japan口座のパスワードはお客様の新しいLiquid
                    Japan口座へ移行されていません。以下からパスワードリセットの手続きを進めてください。
                  </div>
                </div>
              </div>
              <div>
                <Button
                  link={`https://app.liquid.com/ja-jp/forgot-password?email=${liquidUserEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  パスワードリセットページを表示
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SectionWrapper>
    </section>
  );
};

export default EmailNonConflictCase;
