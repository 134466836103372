import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import SectionWrapper from 'components/SectionWrapper';
import Button from 'components/Button';

import HeaderLogo from './HeaderLogo.inline.svg';
import FTXJP_LOGO_BLACK from './FTXJP_LOGO_BLACK.inline.svg';
import ArrowRight from './Arrow-Right.inline.svg';
import AlertInfo from './AlertInfo.inline.svg';
import AlertChecked from './AlertChecked.inline.svg';
import styles from './Banner.module.css';

const EmailConflictCase = ({ ftxUserEmail }) => {
  return (
    <section className={styles.main}>
      <SectionWrapper>
        <div className={styles.pcKeyVisual}>
          <div className={styles.pcKeyVisual_left}>
            <div className={classnames(styles.image, styles.imageFtxToLiquid)}>
              <FTXJP_LOGO_BLACK width={115} height={42} />
              <ArrowRight height={42} style={{ margin: '0 16px' }} />
              <HeaderLogo width={115} height={42} />
            </div>
            <div className={styles.afterMigrationText}>
              <div>FTX Japanのお客様へ、</div>
              <div>お客様のFTX Japan口座は、Liquid Japanへ移管されました。</div>
              <div>
                これはFTX
                Japanプラットフォームを閉鎖するための最終ステップとして実行されました。
              </div>
              <div>
                詳細については
                <a
                  href="https://support-jp.liquid.com/hc/ja/articles/27696005108109"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#00a3e0', textDecoration: 'underline' }}
                >
                  こちら
                </a>
                をご確認ください。
              </div>
              <hr
                style={{
                  marginTop: '12px',
                  marginBottom: '12px',
                  borderTop: '1px solid var(--t-4-a30)',
                }}
              />
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    content: '',
                    left: '7.5px',
                    top: '18px',
                    bottom: '-24px',
                    width: '1px',
                    background: 'var(--success)',
                  }}
                />
                <AlertChecked
                  style={{
                    position: 'absolute',
                    content: '',
                    top: '4px',
                    left: 0,
                    height: '16px',
                    width: '16px',
                    color: 'var(--success)',
                  }}
                />
                <div style={{ marginLeft: '24px', marginBottom: '16px' }}>
                  <div style={{ fontWeight: '600' }}>
                    お客様のFTX Japan口座のメールアドレスは以下です。
                  </div>
                  <div>{ftxUserEmail}</div>
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <AlertInfo
                  style={{
                    position: 'absolute',
                    content: '',
                    top: '4px',
                    left: 0,
                    height: '16px',
                    width: '16px',
                    color: 'var(--information)',
                  }}
                />
                <div style={{ marginLeft: '24px', marginBottom: '16px' }}>
                  <div style={{ fontWeight: '600', marginBottom: '8px' }}>
                    同じメールアドレスのお客様のLiquid
                    Japan口座が既に存在しています。
                  </div>
                  <div>
                    お客様のFTX Japan口座の資産はLiquid
                    Japan準備口座に別途保管されています。Liquid
                    Japan準備口座からお客様のLiquid
                    Japan口座へ資産を移管するため、お客様からいくつかの情報をご提供いただく必要があります。資産移管のための本人確認のプロセスを開始するため、以下から当社カスタマーサポートまでご連絡ください。
                  </div>
                </div>
              </div>
              <Button
                href="https://support-jp.liquid.com/hc/ja/requests/new"
                target="_blank"
                rel="noopener noreferrer"
              >
                カスタマーサポートに連絡
              </Button>
            </div>
          </div>
        </div>
      </SectionWrapper>
    </section>
  );
};

export default EmailConflictCase;
