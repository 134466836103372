import React from 'react';
import { Link } from 'gatsby';

import data from './data';

import styles from './navigation.module.css';

const FooterNavigation = () => (
  <div className={styles.footerNavigation}>
    <div className={styles.row}>
      {data.map((item, index) => {
        return (
          <div key={index} className={styles.column}>
            <h6 className={styles.group}>
              {item.group}
            </h6>
            <ul className={styles.links}>
              {item.links.map(link => {
                return (
                  <li
                    key={link.label}
                    className={styles.link}
                  >
                    {link.innerLink ? (
                      <Link to={link.href}>
                        {link.label}
                      </Link>
                    ) : (
                      <a
                        target={link.href.includes('lite-app') ? '_self' : '_blank'}
                        rel={`noopener noreferrer${link.href.includes('lite-app') ? '' : ' nofollow'}`}
                        href={link.href}
                      >
                        {link.label}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  </div>
);

export default FooterNavigation;
