import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './Checkbox.module.css';

/**
 * To display the checkbox with label
 * @param {object} props
 * @param {boolean} [props.checked=false]
 * @param {(checked: boolean) => void} [props.onChange]
 * @param {React.ReactNode} [props.children='']
 * @returns
 */
const Checkbox = ({ checked = false, onChange, children }) => {
  const name = useMemo(() => uuidv4(), []);
  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };
  return (
    <label htmlFor={name} className={styles.checkbox}>
      <input
        type="checkbox"
        id={name}
        name={name}
        className={styles.checkboxInput}
        checked={checked}
        onChange={handleOnChange}
      />
      <span className="">{children}</span>
    </label>
  );
};

export default Checkbox;
