import React from 'react';
import Banner from './Banner';

const HomeBanner = () => (
  <React.Fragment>
    <Banner />
  </React.Fragment>
);

export default HomeBanner;
