import React from 'react';
// import { Link } from 'gatsby';

import { headerLinks, links } from 'constants/links';
import SectionWrapper from 'components/SectionWrapper';

// import SummerCampaignBanner from './SummerCampaignBanner';
// import ChartList from './ChartList';
import TopMenu from './TopMenu';
import AppMenuLogo from './AppMenuLogo';
import Hamburger from './Hamburger.svg';
import HamburgerOpen from './HamburgerOpen.svg';
import Login from './Login.inline.svg';

import styles from './Header.module.css';

const MAX_WIDTH_MOBILE = 768;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowMenuMobile: false,
      showSubMenu: false,
    };
    this.menu = [
      {
        label: 'お知らせ',
        link: links()?.newsZendesk,
      },
      {
        label: 'FAQ',
        link: links().support,
      },
      // {
      //   label: '採用情報🆕',
      //   link: '/careers/',
      // },
      {
        label: '支払い能力証明🆕',
        link: links().pos,
      },
    ];
  }

  toggleSubMenu = () => {
    if (window.innerWidth < 1200) {
      const { showSubMenu } = this.state;
      this.setState({ showSubMenu: !showSubMenu });
    }
  };

  toggleMenuMobile = () => {
    const { isShowMenuMobile } = this.state;
    this.setState({ isShowMenuMobile: !isShowMenuMobile });
  };

  render() {
    const { logo, width, height } = this.props;
    const { isShowMenuMobile, showSubMenu } = this.state;
    return (
      <header className={styles.main}>
        {/* <SummerCampaignBanner /> */}
        <div className={styles.top}>
          <div className={styles.mobileWrapper}>
            <button
              className={styles.mobileMenuButton}
              onClick={this.toggleMenuMobile}
            >
              <img
                className={styles.hamburger}
                src={isShowMenuMobile ? HamburgerOpen : Hamburger}
                alt="hamburger-image"
              />
            </button>
            <div
              className={styles.mobileMenu}
              style={{
                left: isShowMenuMobile ? '0' : '100%',
                display: isShowMenuMobile ? 'block' : 'none',
              }}
            >
              <SectionWrapper>
                <div className={styles.externalMenu2}>
                  <TopMenu
                    menu={this.menu}
                    showSubMenu={showSubMenu}
                    toggleSubMenu={this.toggleSubMenu}
                  />
                </div>
              </SectionWrapper>
            </div>
            <div
              className={styles.backgroundPanel}
              onClick={this.toggleMenuMobile}
              style={{ display: isShowMenuMobile ? 'block' : 'none' }}
            />
          </div>
          <div className={styles.logoWrapper}>
            <AppMenuLogo logo={logo} />
          </div>
          <div className={styles.container}>
            <div className={styles.externalMenu}>
              <a
                className={styles.cryptact}
                href="https://px.a8.net/svt/ejp?a8mat=3B5I8L+6NXPKI+4DGW+5ZU29"
                rel="nofollow"
              >
                <img
                  alt=""
                  src="https://www23.a8.net/svt/bgt?aid=200131221403&wid=001&eno=01&mid=s00000020408001007000&mc=1"
                />
              </a>
              <img
                border="0"
                width="1"
                height="1"
                src="https://www18.a8.net/0.gif?a8mat=3B5I8L+6NXPKI+4DGW+5ZU29"
                alt=""
              />
              <TopMenu
                menu={this.menu}
                showSubMenu={showSubMenu}
                toggleSubMenu={this.toggleSubMenu}
              />
            </div>
            <div className={styles.separator} />
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.login}
              href={headerLinks().login}
            >
              <Login />
              ログイン
            </a>
          </div>
        </div>
      </header>
    );
  }
}

Header.defaultProps = {
  width: 49,
  height: 42,
};

export default Header;
