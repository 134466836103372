import React from 'react';

import styles from './button.module.css';

const Button = ({ children, className, link, ...others }) => {
  return (
    <a className={`${styles.main} ${className}`} href={link} {...others}>
      {children}
    </a>
  )
};

export default Button;
