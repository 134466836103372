import React from 'react';
import { Link } from 'gatsby';


import styles from './button.module.css';

const Button = ({ children, className, link, ...others }) => {
  return (
    <Link className={`${styles.main} ${className}`} to={link} {...others}>
      {children}
    </Link>
  )
};

export default Button;
