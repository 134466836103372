import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import { headerLinks } from 'constants/links';
import Modal from 'components/Modal/Base';

import DevSettings from './DevSettings';

import styles from './Header.module.css';

const AppMenuLogo = ({ logo }) => {
  const [visible, setVisible] = useState(false);

  let searchParams = false;
  if (typeof window !== 'undefined') {
    searchParams = new URLSearchParams(window.location.search);
  }
  let isDeveloper = false;
  if (searchParams) {
    isDeveloper = searchParams.get('developer') === 'true';
  }

  const [count, setCount] = useState(0);

  const increment = () => setCount((prevCount) => prevCount + 1);

  let timerId = 0;

  // Reset count after 5 seconds passes since the timer starts
  const startTimer = () => {
    if (count === 0) {
      timerId = window.setTimeout(() => {
        setCount(0);
      }, 5 * 1000);
    }
  };

  const onClick = () => {
    startTimer();
    increment();
  };

  // Show the Modal when clicking 5 times
  useEffect(() => {
    if (count > 4) {
      setVisible(true);
    }
  }, [count]);

  useEffect(() => {
    return () => {
      clearTimeout(timerId);
    };
  }, [timerId]);

  if (isDeveloper) {
    return (
      <>
        <div className={styles.mainLink} onClick={onClick}>
          <img className={styles.logo} src={logo} alt="header-logo" />
        </div>
        <Modal
          active={visible}
          onBackdropClick={() => {}}
          style={{ maxWidth: '410px', maxHeight: '100vh', top: '50%' }}
          onCloseRequest={() => {
            setVisible(false);
            setCount(0);
          }}
        >
          <DevSettings />
        </Modal>
      </>
    );
  }

  return (
    <Link className={styles.mainLink} to={headerLinks().liquidLPMain}>
      <img className={styles.logo} src={logo} alt="header-logo" />
    </Link>
  );
};

export default AppMenuLogo;
