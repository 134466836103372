import React from 'react';
// import T from 'prop-types';

import SupportedCrypto from 'components/QuickExchange/SupportedCrypto';
import { setItem } from 'utils/localStorage';

import HomeBanner from './HomeBanner';
// import Intro from './Intro';
import Features from './Features';
// import LiteApp from './LiteApp';
import Support from './Support';
import Story from './Story';
// import Step from './Step';
// import QnA from './QnA';
// import Update from './Update';
// import Monthly from './Monthly';
// import styles from './Home.module.css';

import addMarketingTags from './addMarketingTags';

class Home extends React.PureComponent {
  componentDidMount = () => {
    addMarketingTags();
  };

  render() {
    return (
      <main>
        <HomeBanner />
        {/* <Intro /> */}
        <SupportedCrypto title={'Liquid Japanの取り扱い暗号資産'} />
        <Features />
        {/* <LiteApp /> */}
        <Support />
        <Story />
        {/* <Step /> */}
        {/* <QnA /> */}
        {/* <Update /> */}
        {/* <Monthly /> */}
      </main>
    );
  }
}

export default Home;
