import React from 'react';
import T from 'prop-types';

import styles from './SectionWrapper.module.css'

const SectionWrapper = ({children}) => (
  <div className={styles.section}>
    {children}
  </div>
)

export default SectionWrapper;
