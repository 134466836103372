const normalize = (raw) => {
  const all = raw.map(item => ({
    type: item.term_type,
    url: item.term_document_url,
    version: item.term_version,
  }));
  const eStatement = all.find(item => item.type === 'e-statement');
  const terms = all.filter(item => item.type !== 'e-statement');
  const versions = all.map(item => item.version);
  const version = versions.length ? Math.max(...versions) : null;
  return { eStatement, terms, version };
};

export default normalize;
