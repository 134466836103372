import React from 'react';

import { links } from 'constants/links';
import Button from 'components/InternalLinkButton';
import SectionWrapper from 'components/SectionWrapper';

import StoryBackgroundImage from './StoryBackgroundImage';
import LBQLogo from 'components/Footer/LBQLogo.svg';

import styles from './Story.module.css';

const Story = () => (
  <StoryBackgroundImage className={styles.story}>
    <SectionWrapper>
      <section className={styles.section}>
        <h2 className={styles.header}>
          <img src={LBQLogo} alt='liqudi-japan-logo' width="225" height="58" />
        </h2>
        <div className={styles.content}>
          <h3 className={styles.title}>STORY</h3>
          <p className={styles.desc}>
            暗号資産 / 仮想通貨取引に流動性を提供する新世代の金融サービス、Liquid Japan。<br className={styles.br} />
            金融とその技術のプロたちが作ったプラットフォームで、<br className={styles.br} />
            取引所毎に分断されていた取引を束ねることで暗号資産 / 仮想通貨市場に必要な流動性を提供して、<br className={styles.br} />
            お客様が快適な取引をできるサービスを目指しております。<br className={styles.br} />
            より良いサービスを提供するために我々は日々努力を続けています。
          </p>
        </div>
        <Button className={styles.button} link={links().company}>
          Liquid Japanの運営会社について
        </Button>
      </section>
    </SectionWrapper>
  </StoryBackgroundImage>
)

export default Story;
