import React from 'react';

import SectionWrapper from 'components/SectionWrapper';
import { footerLinks } from 'constants/links';

import styles from './CompanyInfo.module.css';

const jfsa = 'https://www.fsa.go.jp/menkyo/menkyoj/kasoutuka.pdf';

const CompanyInfo = () => (
  <SectionWrapper>
    <div className={styles.jpcompanyinfo}>
      <div className={styles.content}>
        FTX Japan株式会社<br />
        暗号資産交換業 関東財務局長 第 00002 号<br />
        金融商品取引業 関東財務局長（金商）第 3297 号<br />
        加入協会：一般社団法人日本暗号資産ビジネス協会 / 一般社団法人日本暗号資産取引業協会<br />
        金融庁が公開している暗号資産交換業者登録一覧は<a className={styles.link} href={jfsa} target="_blank" rel="noopener noreferrer">こちら</a>（PDF: 62KB）<br />
      </div>
      <div className={styles.content}>
        メールでのお問合せ窓口：<br />
        <a className={styles.link} href={footerLinks().contactSupport} target="_blank" rel="noopener noreferrer">問い合わせページ</a>へ<br />
      </div>
      <div className={styles.content}>
        〒101-0054&nbsp;東京都千代田区神田錦町3丁目17番地廣瀬ビル4階<br />
        代表電話番号：03-6630-7554<br />
        ※こちらの番号でのサポート対応は受けかねます。ご注意ください。
      </div>
    </div>
  </SectionWrapper>
);

export default CompanyInfo;
