import T from 'prop-types';
import React from 'react';

const DEFAULT_IMAGE = `${process.env.GATSBY_STATIC_HOST}/currencies/crypto.svg`;
const onLoadingError = (e) => {
  e.target.src = DEFAULT_IMAGE;
};

const ExchangeIcon = ({ id, currency, size = 24, className }) => {
  const src = `${process.env.GATSBY_STATIC_HOST}/currencies/${currency}.svg`;
  return (
    <span id={id}>
      <svg className={className} width={size} height={size}>
        <foreignObject width={size} height={size}>
          <img
            alt={currency}
            width={size}
            height={size}
            src={src}
            onError={onLoadingError}
          />
        </foreignObject>
      </svg>
    </span>
  );
};

ExchangeIcon.propTypes = {
  id: T.string,
  currency: T.string,
  size: T.oneOfType([T.string, T.number]),
  className: T.string
};

export default ExchangeIcon;
