import React from 'react';

import SectionWrapper from 'components/SectionWrapper';

// import Carousel from './Carousel';
// import Number02 from './Number02.svg';
// import Number2 from './Number2.svg';
import AppOnDisplayImage from './AppOnDisplayImage.js';

import styles from './Chart.module.css';

const slides = [];

const Chart = () => (
  <div className={styles.chart} id="chart">
    <SectionWrapper>
      <h3 className={styles.header}>
        {/* <img className={styles.numberPC} src={Number02} alt="03-image"/> */}
        {/* <img className={styles.numberSP} src={Number2} alt="3-image"/> */}
        <div className={styles.text}>
          わかりやすく、<br className={styles.br}/>高機能な取引ツール
        </div>
      </h3>
      <div className={styles.contentWrapper}>
        <AppOnDisplayImage className={styles.displayImage }/>
        <div className={styles.content}>
          {/* <Carousel slides={slides}/> */}
          <div className={styles.title}>
            Liquid Japan では、<br />
            金融商品経験者に嬉しいツールを提供
          </div>
          <ul className={styles.list}>
            <li>マーケット分析をしやすい描画ツール</li>
            <li>ローソク、平均足、バーチャートなど7種類の足を閲覧可能</li>
            <li>豊富なインジケーター</li>
            <li>テンプレート保存機能追加</li>
            <ol>保存したチャート設定のリセット</ol>
            <ol>デバイス依存のないテンプレート保存機能</ol>
          </ul>
        </div>
      </div>
    </SectionWrapper>
  </div>
);

export default Chart;
