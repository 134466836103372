import axios from 'axios';
import handleError from './handleError';


const baseHeader = {
  Accept: 'application/json',
  'X-Quoine-API-Version': 2,
  'X-Quoine-Device': 'web-native',
  'Content-Type': 'application/json',
};

const main = (method, url, options = {}) => {
  const header = options.header || {};
  const headers = { ...baseHeader, ...header };

  const config = {
    baseURL: process.env.GATSBY_API_HOST,
    headers,
    method,
    url,
  };
  if (options.body) {
    config.data = options.body;
  }
  return axios(config)
    .then(raw => raw.data)
    .catch(handleError);
};

const REST = {
  get: main.bind(null, 'get'),
  post: main.bind(null, 'post'),
  put: main.bind(null, 'put'),
  del: main.bind(null, 'delete'),
};

// window.DEBUGres = REST;

export default REST;
