import React from 'react';
import { Link } from 'gatsby';

import HeaderWithSubMenu from './HeaderWithSubMenu';
import styles from './styles.module.css';

const TopMenu = ({ menu, showSubMenu, toggleSubMenu }) => (
  menu?.map((item, index) =>
    !item.link
      ? (<HeaderWithSubMenu
          data={item}
          showSubMenu={showSubMenu}
          toggleSubMenu={toggleSubMenu}
        />)
      : item.link.indexOf('http') !== -1
        ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
          href={item.link}
          key={index}
        >
          {item.label}
        </a>)
        : (
          <Link className={styles.link} to={item.link}>
            {item.label}
          </Link>
        )
  )
);

export default TopMenu;
