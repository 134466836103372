import React from 'react';

// import Security from './Security';
import Chart from './Chart';

const Features = () => (
  <div id="features">
    <section>
      {/* <Security /> */}
      <Chart />
    </section>
  </div>
);

export default Features;
