import { footerLinks as links } from 'constants/links';

export default [
  // {
  //   group: '取引ツール',
  //   links: [
  //     {
  //       label: '現物取引（スポット取引）',
  //       href: links().trade,
  //       innerLink: false,
  //     },
  //     {
  //       label: 'レバレッジ取引',
  //       href: links().btcLeverage,
  //       innerLink: false,
  //     },
  //   ],
  // },
  {
    group: 'サービスのご案内',
    links: [
      // {
      //   label: '取引通貨',
      //   href: links().tradingCurrency,
      //   innerLink: true,
      // },
      {
        label: '手数料',
        href: links().fee,
        innerLink: true,
      },
      // {
      //   label: 'レバレッジ取引について',
      //   href: links().leverage,
      //   innerLink: true,
      // },
      // {
      //   label: 'Liquid Japanアプリ',
      //   href: links().liteApp,
      //   innerLink: false,
      // },
      {
        label: 'セキュリティ',
        href: links().security,
        innerLink: true,
      },
      // {
      //   label: 'APIドキュメント（日本語）',
      //   href: links().apiDocsJP,
      //   innerLink: false,
      // },
      // {
      //   label: 'APIドキュメント（英語）',
      //   href: links().apiDocsEN,
      //   innerLink: false,
      // },
      // {
      //   label: 'システム状況について',
      //   href: links().status,
      //   innerLink: false,
      // },
    ],
  },

  {
    group: '会社概要',
    links: [
      {
        label: 'FTX Japanについて',
        href: links().aboutUs,
        innerLink: true,
      },
      // {
      //   label: '採用情報',
      //   href: '/careers/',
      //   innerLink: true,
      // },
      // {
      //   label: 'ロードマップ',
      //   href: links().roadmap,
      //   innerLink: true,
      // },
      // {
      //   label: '採用情報（英語サイト）',
      //   href: links().careers,
      //   innerLink: false,
      // },
    ],
  },

  // {
  //   group: '暗号資産 / 仮想通貨を学ぶ',
  //   links: [
  //     // {
  //     //   label: '暗号資産 / 仮想通貨とは？Bitcoinって？',
  //     //   href: links().bitcoinIntro,
  //     //   innerLink: true,
  //     // },
  //     // {
  //     //   label: 'チャートの見方',
  //     //   href: links().chartIntro,
  //     //   innerLink: true,
  //     // },
  //     // {
  //     //   label: '取引を開始してみる',
  //     //   href: links().tradeIntro,
  //     //   innerLink: true,
  //     // },
  //     {
  //       label: 'Liquidブログ',
  //       href: links().blog,
  //       innerLink: false,
  //     },
  //   ],
  // },

  {
    group: 'サポート',
    links: [
      {
        label: 'お知らせ',
        href: links()?.newsZendesk,
        innerLink: false,
      },
      {
        label: 'FAQ・サポート',
        href: links().support,
        innerLink: false,
      },
      {
        label: 'お問い合わせ',
        href: links().contactSupport,
        innerLink: false,
      },
      {
        label: '苦情および紛争解決窓口について',
        href: links().complaints,
        innerLink: false,
      },
    ],
  },
];
