import REST from '../REST';
import { normalize } from './normalize';

const signUp = (form, captcha) => {
  const body = normalize(form);
  const options = {
    body: {
      ...body,
      ...{ recaptcha_response: captcha },
    },
    useCache: false,
    vendorId: 3,
  };

  return REST
    .post('/users', options)
    .then(raw => ({ id: raw.id }))
    .catch(error => Promise.reject({
      ...error,
      message: error.message,
    }));
};

export const registerViaEmails = (body) => {
  const options = {
    useCache: false,
    body: {
      ...body,
    },
    header: {
      'X-Quoine-Vendor-ID': process.env.GATSBY_JFSA_ID,
      'Cache-Control': 'no-cache',
    },
  };

  return REST
    .post('/register_via_emails', options)
    .then(raw => ({ id: raw.id }))
    .catch(error => Promise.reject({
      ...error,
      message: error.message,
    }));
};

export default signUp;
