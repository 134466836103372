import React from 'react';

import s from './AffiliatePopup.module.css';

const AffiliatePopup = ({ logo, toggle }) => (
  <div className={s.background}>
    <div className={s.popupClose}>
      <div />
      <button onClick={toggle}>
        ✕
      </button>
    </div>
    <div className={s.content}>
      <img className={s.logo} src={logo} alt="logo" />
      <p className={s.title}>Liquid Japanへようこそ</p>
      <p className={s.text}>ここからFTX Japan株式会社が提供するウェブサイトとなります。</p>
      <p className={s.text}>直前にご覧いただいたウェブサイトの内容に関しましては、</p>
      <p className={s.text}>各ウェブサイト運営者の評価や感想となり、当社がその内容について保証するものではありません。</p>
      <button className={s.button} onClick={toggle}>Liquid Japanのページへ進む</button>
    </div>
  </div>
)

export default AffiliatePopup;
