import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import SectionWrapper from 'components/SectionWrapper';
import { SECRET_SIGNUP } from 'constants/localStorageKey';
import { getFtxOfflineLinkingsValidate } from 'resources/ftxLinking';
import Loading from 'components/Loading';

import SignUpPopup from './SignUpPopup';
import EmailConflictCase from './EmailConflictCase';
import EmailNonConflictCase from './EmailNonConflictCase';
// import ForceMigrationInterim from './ForceMigrationInterim';

import Emblem from './Emblem.inline.svg';
import HeaderLogo from './HeaderLogo.inline.svg';
import FTXJP_LOGO_BLACK from './FTXJP_LOGO_BLACK.inline.svg';
import ArrowRight from './Arrow-Right.inline.svg';
import styles from './Banner.module.css';

const HomeBannerInside = () => {
  const [isValidSig, setIsValidSig] = useState(false);
  const [supportCase, setSupportCase] = useState(false);
  const [ftxUserEmail, setFtxUserEmail] = useState('');
  const [liquidUserEmail, setLiquidUserEmail] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  let showHidden = false;
  if (typeof window !== 'undefined') {
    showHidden = window.localStorage.getItem(SECRET_SIGNUP) === 'true';
  }

  let ftxSig = '';
  if (typeof window !== 'undefined') {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams) {
      ftxSig = searchParams.get('ftx_sig');
    }
  }

  useEffect(() => {
    const getValidate = async (cb) => {
      if (ftxSig) {
        const res = await getFtxOfflineLinkingsValidate(ftxSig);
        setIsValidSig(res?.valid);
        setSupportCase(res?.support_case);
        setFtxUserEmail(res?.ftx_user_email);
        setLiquidUserEmail(res?.email || res?.ftx_user_email);
      }
      cb();
    };

    try {
      getValidate(() => setIsLoading(false));
    } catch {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Loading />
      </div>
    );
  }

  if (showHidden) {
    return (
      <section className={styles.main}>
        <SectionWrapper>
          <div className={styles.pcKeyVisual}>
            <div className={styles.pcKeyVisual_left}>
              <div
                className={classnames(styles.image, styles.imageFtxToLiquid)}
              >
                <FTXJP_LOGO_BLACK width={115} height={42} />
                <ArrowRight height={42} style={{ margin: '0 16px' }} />
                <HeaderLogo width={115} height={42} />
              </div>
              <div className={styles.subTitle}>
                Liquid Japan資産返還用口座の登録
              </div>
              <div className={styles.signUpPopupWrp}>
                <SignUpPopup ftxSig={ftxSig} />
              </div>
              <div className={styles.subTitleDesc}>
                <div>
                  ※ メール件名「【重要】Liquid
                  Japanの口座開設に関するご案内」を受信したメールアドレスでご登録をお願い致します。
                  異なるメールアドレスをご登録された場合は口座の確認にお時間を頂く場合がございます。
                </div>
                <div>
                  ※ Liquid Japanご登録の際は、FTX
                  Japanご登録時の区分（個人で登録された方は個人として、法人で登録された方は法人として）に応じて登録手続きをお願い致します。
                </div>
              </div>
            </div>
          </div>
        </SectionWrapper>
      </section>
    );
  }

  // if (isValidSig) {
  //   return <ForceMigrationInterim />;
  // }

  if (isValidSig && supportCase === 'case_a') {
    return <EmailConflictCase ftxUserEmail={ftxUserEmail} />;
  }

  if (isValidSig && supportCase === 'case_b') {
    return (
      <EmailNonConflictCase
        ftxUserEmail={ftxUserEmail}
        liquidUserEmail={liquidUserEmail}
      />
    );
  }

  return (
    <section className={styles.main}>
      <SectionWrapper>
        <div className={styles.pcKeyVisual}>
          <div className={styles.pcKeyVisual_left}>
            <div className={styles.image}>
              <Emblem />
            </div>
            <div className={styles.subTitle}>
              ※Liquid Japanは新規登録受付停止中です
            </div>
            {/* {!!ftxSig && (
              <div className={styles.sigInvalid}>
                無効なシグネチャです。新規登録を行うためには有効なシグネチャを使用してください。
              </div>
            )} */}
          </div>
        </div>
      </SectionWrapper>
    </section>
  );
};

export default HomeBannerInside;
