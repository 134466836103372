import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "./Footer.module.css";
import Navigation from "./Navigation";
import Documents from './Documents';
import Social from "./Social";
import Caution from "./Caution";
import CompanyInfo from "./CompanyInfo";
import LBQLogo from './LBQLogo.svg';
import SectionWrapper from "components/SectionWrapper";

export const BottomFooter = () => (
  <div className={styles.bottom}>
    <CompanyInfo />
    <div className={styles.copyright}>
      © {new Date().getFullYear()}, FTX Japan株式会社. All Rights Reserved.
    </div>
  </div>
);

const Footer = () => (
  <footer className={styles.main}>
    <SectionWrapper>
      <div className={styles.title}>
        <img className={styles.lbqLogo} src={LBQLogo} alt='liqudi-japan-logo' width="145" height="37" />
        <div className={styles.desc}>
          Liquid Japanは、ビットコイン（Bitcoin）などの<br />
          暗号資産 / 仮想通貨を売買・取引ができる暗号資産 / 仮想通貨取引所です。
        </div>
      </div>
    </SectionWrapper>
    <div className={styles.middle}>
      <SectionWrapper>
        <div className={styles.navigation}>
          <Navigation />
        </div>
        <div className={styles.container}>
          <Documents />
          <Social />
          <a
            className={styles.cryptact}
            href="https://px.a8.net/svt/ejp?a8mat=3B5I8L+6NXPKI+4DGW+5ZU29"
            rel="nofollow"
          >
            <img alt="" src="https://www23.a8.net/svt/bgt?aid=200131221403&wid=001&eno=01&mid=s00000020408001007000&mc=1" />
          </a>
          <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3B5I8L+6NXPKI+4DGW+5ZU29" alt="" />
        </div>
      </SectionWrapper>
    </div>
    <div className={styles.caution}>
      <Caution />
    </div>
    <BottomFooter />
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

export default Footer
