import React from 'react';
import { Link } from 'gatsby';

import styles from './HeaderWithSubMenu.module.css';

const SubMenu = ({ list, className }) => (
  <div className={`${styles.subNav} ${className ? className : ''}`}>
    <div className={styles.spacer} />
    <div className={styles.content}>
      <ul>
        {list.map(item => (
          <li className={styles.item} key={item.name}>
            <Link className={styles.link} to={item.href}>
              {item.name}
            </Link>
            <div className={styles.divider} />
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export const HeaderWithSubMenu = ({ data, toggleSubMenu, showSubMenu }) => (
  <div className={styles.header}>
    <div className={styles.label} onClick={toggleSubMenu}>
      {data.label}
    </div>
    <SubMenu
      list={data.sub}
      className={`${styles.dropdown} ${showSubMenu ? styles.active : ''}`}
    />
  </div>
)

export default HeaderWithSubMenu;
