import { useEffect, useState } from 'react';

/**
 * @typedef Banner
 * @property {string} id
 * @property {string} imgSrc
 * @property {string} url
 * @property {string} until
 */

/**
 * Receive the banner link dictionary as parameter and transform it into an array of banner item
 * @param {object} linkDictionary
 * @returns
 */
const produceBanner = (linkDictionary) => {
  if (!linkDictionary) {
    return {};
  }

  return {
    id: linkDictionary.id,
    imgSrc: `${process.env.GATSBY_ASSETS_HOST}/jp-marketing-banner/${linkDictionary.id}.png`,
    url: linkDictionary.url,
    until: linkDictionary.until,
  };
};

/**
 * This is to get the Marketing banner items from `Amazon S3`
 */
const useBanner = () => {
  const [banner, setBanner] = useState({});

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const rawResponse = await fetch(
          `${process.env.GATSBY_ASSETS_HOST}/jp-marketing-banner/jp-banner-url.json`,
          { credentials: 'omit', cache: 'no-store' }
        );
        const linkDictionary = await rawResponse.json();
        setBanner(produceBanner(linkDictionary.front_page_banner));
      } catch (err) {
        console.error(err);
      }
    };
    fetchLinks();
  }, []);

  return banner;
};

export default useBanner;
