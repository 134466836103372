import React from 'react';

import styles from './RadioButton.module.css';

class RadioButton extends React.PureComponent {
  onChange = (event) => {
    this.props.onChange(event.target.value);
  }
  render() {
    const { id, label, value, checked, className, ...others } = this.props;
    return (
      <div className={`${styles.main} ${className ? className : ''}`}>
        <div className={`${styles.check} ${checked && styles.checked}`} />
        <input
          {...others}
          id={`${id}--input`}
          className={styles.input}
          type="radio"
          value={value}
          checked={checked}
          onChange={this.onChange}
        />
        <label
          id={`${id}--label`}
          className={styles.label}
          htmlFor={`${id}--input`}
        >
          {label}
        </label>
      </div>
    );
  }
}

export default RadioButton;
