import React from 'react';

import { footerLinks as links } from 'constants/links';
import SectionWrapper from 'components/SectionWrapper';
import styles from './Caution.module.css';

const Caution = () => (
  <SectionWrapper>
    <section className={styles.caution}>
      <div className={styles.cautionText}>
        <div>
          ≪暗号資産 / 仮想通貨の取引を行う際には、以下の注意点にご留意ください。≫
        </div>
        <ul className={styles.ulLevel1}>
          <li className={styles.disc}>暗号資産 / 仮想通貨は、日本円や米ドルなどのように国がその価値を保証している「法定通貨」ではありません。インターネット上でやりとりされる電子データです。</li>
          <li className={styles.disc}>暗号資産 / 仮想通貨は、価格が変動することがあります。暗号資産 / 仮想通貨の価格が急落したり、ネットワークに問題が発生して突然無価値になってしまうなど、損失を被る可能性があります。</li>
          <li className={styles.disc}>暗号資産 / 仮想通貨の取引を行う場合、価格変動の他に流動性・システム等の様々なリスクが存在します。取引の仕組みやリスク等について、各種規約・取引ルール等の内容をよくご理解いただき、ご自身の責任と判断で取引を行ってください。<a className={styles.link} href={links().rulesAndEtc} target="_blank" rel="noopener nofollow noreferrer">各種規定はこちら</a></li>
          <li className={styles.disc}>取引いただく際には、各取引の種類等に応じて所定の手数料をご負担いただく場合があります。<a className={styles.link} href={links().feeHelp} target="_blank" rel="noopener nofollow noreferrer">手数料一覧はこちら</a></li>
          <li className={styles.disc}>レバレッジ取引に関しては、各種規約・取引ルール等の内容をお読みいただき、必要証拠金の計算方法、証拠金率等レバレッジ取引の仕組みや、預託した証拠金を上回る損失が発生する可能性等レバレッジ取引特有のリスク等について、よくご理解した上でご自身の責任と判断で取引を行ってください。<a className={styles.link} href={links().rulesAndEtc} target="_blank" rel="noopener nofollow noreferrer">各種規定はこちら</a></li>
          <li className={styles.disc}>暗号資産 / 仮想通貨を利用した詐欺や悪質商法に御注意ください。暗号資産 / 仮想通貨を取り扱う仮想通貨交換業者は金融庁・財務局への登録が必要です。当社は、暗号資産交換業者 関東財務局長 第00002号として登録を行っています。</li>
          <li className={styles.disc}>お客様登録情報に変更が生じた場合は、速やかに<a className={styles.link} href={links().contactSupport} target="_blank" rel="noopener nofollow noreferrer">問い合わせページ</a>より変更手続きをお願いいたします。</li>
          <li className={styles.disc}>口座開設時における年齢基準は原則として満20歳以上満80歳未満としております。</li>
        </ul>
      </div>
    </section>
  </SectionWrapper>
);

export default Caution;
