import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import CurrencyIcon from 'components/QuickExchange/QuickExchange/components/CurrencyIcon';
import { loadCurrenciesArray } from 'resources/currencies';
import { links } from 'constants/links';
import { currenciesList } from 'constants/currenciesParams';

import styles from './SupportedCrypto.module.css';
import { defaultJpCurrencies } from './defaultJpCurrencies.js';

const id = 'SupportedCrypto';

const SupportedCrypto = ({ title }) => {
  const [currencies, setCurrencies] = useState([]);
  const [currencySize, setCurrencySize] = useState(32);

  useEffect(() => {
    const setCryptoCurrencies = async () => {
      try {
        const currenciesOrig = await loadCurrenciesArray();

        const cryptoCurrencies = currenciesOrig
          .filter((i) => currenciesList.includes(i.currency))
          .map((i) => i.currency);
        setCurrencies(cryptoCurrencies);
      } catch (error) {
        const cryptoCurrencies = defaultJpCurrencies
          .filter((i) => currenciesList.includes(i.currency))
          .map((i) => i.currency);
        setCurrencies(cryptoCurrencies);
      }
    };
    setCryptoCurrencies();
  }, []);

  useEffect(() => {
    resolveSize();
    window.addEventListener('resize', () => {
      resolveSize();
    });
  }, []);

  const resolveSize = () => {
    if (window.innerWidth > 1200) {
      setCurrencySize(48);
    } else if (window.innerWidth > 768) {
      setCurrencySize(40);
    } else {
      setCurrencySize(32);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>{title}</div>
      <div>
        <div className={styles.content}>
          {currencies?.length > 0 &&
            currencies.map((currency) => {
              return (
                <div className={styles.item} key={currency}>
                  <div className={styles.image}>
                    <CurrencyIcon
                      id={`${id}--icon-${currency}`}
                      currency={currency}
                      size={currencySize}
                    />
                  </div>
                  <div className={styles.title}>{currency}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SupportedCrypto;
