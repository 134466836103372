import REST from '../REST';

export const getFtxOfflineLinkingsValidate = async (ftx_sig) => {
  const url = `/users/ftx_offline_linkings/validate?ftx_sig=${ftx_sig}`;
  const options = {
    useCache: false,
    header: {
      'X-Quoine-Vendor-ID': process.env.GATSBY_JFSA_ID,
      'Cache-Control': 'no-cache',
    },
  };

  try {
    const res = await REST.get(url, options);
    return res;
  } catch {}
};
