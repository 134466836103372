import REST from '../REST';
import normalize from './normalize';

export const load = ({ all }) => {
  let url = '/privacy_policies';
  if (all) { url = `${url}?all=true`; }
  const options = {
    useCache: false,
    vendorId: process.env.GATSBY_PTE_ID,
    header: {
      'Cache-Control': 'no-cache',
    },
  };
  return REST.get(url, options).then(normalize);
};

export const update = (version) => (
  REST
    .put('/users/term_version', {
      body: { term_version: version },
    })
);

export const loadJp = () => {
  const url = '/privacy_policies?all=true';
  const options = {
    useCache: false,
    header: {
      'X-Quoine-Vendor-ID': process.env.GATSBY_JFSA_ID,
      'Cache-Control': 'no-cache',
    },
  };

  return REST.get(url, options).then(normalize);
};
