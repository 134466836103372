import React, { useState } from 'react';

import Checkbox from 'components/MarketingBanner/Checkbox';
import { SECRET_SIGNUP } from 'constants/localStorageKey';

import s from './DevSettings.module.css';

const DevSettings = () => {
  const [signup, setSignup] = useState(
    typeof window !== 'undefined'
      ? window.localStorage.getItem(SECRET_SIGNUP) === 'true'
      : false
  );

  const models = [
    {
      name: 'Enable Signup',
      keyName: SECRET_SIGNUP,
      value: signup,
      onChange: (el) => {
        setSignup(el);
        window.localStorage.setItem(SECRET_SIGNUP, String(el));
      },
    },
  ];

  return (
    <div className={s.tableWrap}>
      <div className={s.title}>Developer Options</div>
      {models.map((el, index) => (
        <div className={s.tableRow} key={`el--${index}`}>
          <div>
            <Checkbox
              id={`${el.keyName}--checkbox`}
              checked={el.value}
              onChange={el.onChange}
            >
              {el.name}
            </Checkbox>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DevSettings;
