import { links } from 'constants/links';

export const currenciesParams = {
  BTC: {
    currency: 'BTC',
    productPair: 'BTCJPY',
    jpLabel: 'ビットコイン',
    enLabel: 'Bitcoin',
    chartLink: links().chartBTC,
    precision: 0,
  },
  ETH: {
    currency: 'ETH',
    productPair: 'ETHJPY',
    jpLabel: 'イーサリアム',
    enLabel: 'Ethereum',
    chartLink: links().chartETH,
    precision: 0,
  },
  XRP: {
    currency: 'XRP',
    productPair: 'XRPJPY',
    jpLabel: 'リップル',
    enLabel: 'Ripple',
    chartLink: links().chartXRP,
    precision: 3,
  },
  SOL: {
    currency: 'SOL',
    productPair: 'SOLJPY',
    jpLabel: 'ソラナ',
    enLabel: 'Solana',
    chartLink: links().chartSOL,
    precision: 0,
  },
  DOT: {
    currency: 'DOT',
    productPair: 'DOTJPY',
    jpLabel: 'ポルカドット',
    enLabel: 'Polkadot',
    chartLink: links().chartDOT,
    precision: 3,
  },
  FTT: {
    currency: 'FTT',
    productPair: 'FTTJPY',
    jpLabel: 'FTXトークン',
    enLabel: 'FTX Token',
    chartLink: links().chartFTT,
    precision: 0,
  },
  BCH: {
    currency: 'BCH',
    productPair: 'BCHJPY',
    jpLabel: 'ビットコインキャッシュ',
    enLabel: 'Bitcoin cash',
    chartLink: links().chartBCH,
    precision: 0,
  },
  LTC: {
    currency: 'LTC',
    productPair: 'LTCJPY',
    jpLabel: 'ライトコイン',
    enLabel: 'Litecoin',
    chartLink: links().chartLTC,
    precision: 0,
  },
  BAT: {
    currency: 'BAT',
    productPair: 'BATJPY',
    jpLabel: 'ベーシックアテンショントークン',
    enLabel: 'BAT',
    chartLink: links().chartBAT,
    precision: 3,
  },
  DOGE: {
    currency: 'DOGE',
    productPair: 'DOGEJPY',
    jpLabel: 'ドージ',
    enLabel: 'DOGE',
    chartLink: '',
    precision: 3,
  },
  AVAX: {
    currency: 'AVAX',
    productPair: 'AVAXJPY',
    jpLabel: 'アバランチ',
    enLabel: 'AVAX',
    chartLink: '',
    precision: 3,
  },
  MKR: {
    currency: 'MKR',
    productPair: 'MKRJPY',
    jpLabel: 'メイカー',
    enLabel: 'MKR',
    chartLink: '',
    precision: 3,
  },
  OMG: {
    currency: 'OMG',
    productPair: 'OMGJPY',
    jpLabel: 'オーエムジー',
    enLabel: 'OMG',
    chartLink: '',
    precision: 3,
  },
  ENJ: {
    currency: 'ENJ',
    productPair: 'ENJJPY',
    jpLabel: 'エンジンコイン',
    enLabel: 'ENJ',
    chartLink: '',
    precision: 3,
  },
};

export const currenciesList = [
  'XRP',
  'BCH',
  'BTC',
  'LTC',
  'SOL',
  'DOT',
  'DOGE',
  'AVAX',
  'MKR',
  'ETH',
  'FTT',
  'BAT',
  'OMG',
  'ENJ',
];

export const currenciesOrders = Object.keys(currenciesParams);

export const jpyQuoteProductsOrders = Object.keys(currenciesParams).map(
  (currency) => `${currency}JPY`
);

export const multiChainCurrencies = ['FTT'];

export const dwfCurrencies = ['ETH', 'FTT', 'BAT', 'OMG', 'ENJ', 'BTC'];
export const dwfNetwork = ['ERC20'];
