import REST from '../REST';
import normalize from './normalize';

const options = {
  header: {
    'X-Quoine-Vendor-ID': process.env.GATSBY_JFSA_ID,
    'Cache-Control': 'no-cache',
  },
};

export const load = () =>
  REST.get('/currencies', options)
    .then((models) => models.map(normalize))
    .then((models) =>
      models.reduce(
        (prev, model) => ({
          ...prev,
          [model.currency]: model,
        }),
        {}
      )
    );

export const loadCurrenciesArray = async () => {
  const res = await REST.get('/currencies', options).then((models) =>
    models.map(normalize)
  );
  return res;
};
