import React from 'react';

import SectionWrapper from 'components/SectionWrapper';
import Button from 'components/Button';
import { links } from 'constants/links';

import FAQ from './FAQ.svg';
import Mail from './Mail.svg';

import styles from './Support.module.css';

const Support = () => (
  <div className={styles.support}>
    <SectionWrapper>
      <section className={styles.section}>
        <h2 className={styles.header}>Liquid Japanサポート窓口</h2>
        <div className={styles.desc}>
          資産返還手続きに関してのご質問やお困りの際は下記窓口からお問合せください。
        </div>
        <div className={styles.desc}>
          カスタマーサポート営業時間 : 平日午前10時から午後5時
        </div>
        <div className={styles.descNote}>
          ※お手続きやサービスに関するお問い合わせにつきましては、お電話でのサポート窓口は設けておりません。
        </div>
        <div className={styles.content}>
          <div className={styles.container}>
            <div className={styles.item}>
              <div className={styles.icon}>
                <img className={styles.image} src={FAQ} alt="faq-image" />
              </div>
              <Button
                className={styles.button1}
                link="https://support-jp.liquid.com/hc/ja"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </Button>
            </div>
            <div className={styles.item}>
              <div className={styles.icon}>
                <img className={styles.image} src={Mail} alt="mail-image" />
              </div>
              <Button
                className={styles.button2}
                link={links().contactSupport}
                target="_blank"
                rel="noopener noreferrer"
              >
                お問い合わせフォームへ
              </Button>
            </div>
          </div>
        </div>
      </section>
    </SectionWrapper>
  </div>
);

export default Support;
