import React from 'react';
import T from 'prop-types';
import { socialLinks } from 'constants/links';

import styles from './Social.module.css';
import getSocialData from './getSocialData';

const hiddenSocials = ['instagram', 'linkedin'];

const FooterSocial = () => (
  <div className="container reset-padding">
    <nav>
      <ul className={styles.footerSocial}>
        {getSocialData().map((item, index) => {
          if (hiddenSocials.indexOf(item.link) !== -1) {
            return null;
          }
          return (
            <li key={index}>
              <a
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
                href={socialLinks()[item.link]}
                title={item.label}
              >
                <img
                  className={styles.icon}
                  src={item.icon}
                  alt={item.label}
                  width={item.size.width}
                  height={item.size.height}
                />
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  </div>
);

export default FooterSocial;
