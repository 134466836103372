/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// import image from 'images/liquid-banner-seo.png';

function SEO({ title, description, lang = jp, meta, link }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            robots
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;
  const titleTemplate = `${title} | ${site.siteMetadata.title}`;
  const host = process.env.GATSBY_SITE_HOST;
  return (
    <Helmet
      htmlAttributes={{
        lang,
        prefix: "og: http://ogp.me/ns#",
      }}
      title={title}
      titleTemplate={title}
      link={[{
        rel: `canonical`,
        href: `https://www.liquid.com/ja${link}`,
      }]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: site.siteMetadata.author,
        },
        {
          name: `robots`,
          content: site.siteMetadata.robots,
        },
        {
          property: `og:locale`,
          content: `ja`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: `Liquid Japan`,
        },
        {
          property: `og:image`,
          content: `https://be.liquid.com/hubfs/Marketing-Japan/twitter-card.png`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `og:url`,
          content: `https://www.liquid.com/ja${link}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: `https://be.liquid.com/hubfs/Marketing-Japan/twitter-card.png`,
        },
        {
          name: `twitter:site`,
          content: `@liquidbyftx`,
        },
        {
          name: `twitter:url`,
          content: `https://www.liquid.com/ja${link}`,
        },
        {
          name: `google-site-verification`,
          content: `PxBh7n8nlFztzm5HLFKX2_GihmNluOaO5F3bPhwmkKs`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO;
