import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './Base.module.css';

const BaseModal = ({
  active,
  children,
  onBackdropClick,
  onCloseRequest,
  hideCloseButton = false,
  className = '',
  kind,
  ...props
}) => {
  useEffect(() => {
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [active]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!active) {
    return null;
  }

  const clss = `${styles.modalBody} ${className || ''}`;

  return (
    <div className={styles.main}>
      <div className={styles.backdrop} onClick={onBackdropClick} />
      <div className={clss} {...props}>
        {!hideCloseButton && (
          <button className={styles.modalClose} onClick={onCloseRequest}>
            ✕
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

BaseModal.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onBackdropClick: PropTypes.func,
  kind: PropTypes.oneOf(['default', 'pure']),
  hideCloseButton: PropTypes.bool,
};

BaseModal.defaultProps = {
  onBackdropClick: () => null,
};

export default BaseModal;
