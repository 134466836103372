import React from 'react';

import data from './data';
// import LinkIcon from './LinkIcon.png';

import styles from './Documents.module.css';

const Documents = () => (
  <div className={styles.documents}>
    <div className={styles.row}>
      <div className={styles.column}>
        <ul className={styles.links}>
          {data.map(link => {
            return (
              <li
                key={link.label}
                className={styles.link}
              >
                <a href={link.href} target="_blank" rel="noopener nofollow noreferrer">
                  {link.label}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  </div>
);

export default Documents;
